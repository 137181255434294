import React from 'react';
import MainLayout from '@app/layouts/MainLayout';
import {Container, Row, Col} from 'react-bootstrap';

import ProjectsAndProcesses from './ProjectsAndProcesses';
import ActiveProjectsHeatMap from './ActiveProjectsHeatMap';
// import NewProjectsList from './NewProjectsList';
import TotalsWidget from './TotalsWidget';

const Home = () => {
  return (
    <MainLayout path="/overview">
      <Container fluid style={{minHeight: 'calc(100vh - 173px)'}}>
        <div className="mt-4">
          <TotalsWidget />
        </div>
        <div className="mt-4">
          <Row>
            <Col md={5}>
              <ProjectsAndProcesses />
            </Col>
            <Col md={7}>
              <ActiveProjectsHeatMap />
            </Col>
          </Row>
        </div>
      </Container>
    </MainLayout>
  );
};

export default Home;
