import styled from 'styled-components';

export const Header = styled.header`
  background-color: #fdfdfd;
  padding: 17px;
  ${'' /* border-bottom: 0.5px solid #ccc; */}
  box-shadow:rgba(0, 0, 0, 0.16) 0px 1px 1px;
  & .logo {
    width: 158px;
    height: 58px;
  }
  & .brand-text {
    text-align: left;
    letter-spacing: 0px;
    color: #27683f;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    padding-left: 20px;
    line-height: 1.25;
  }
  & .brand-divider-vert {
    display: inline-block;
    width: 1px;
    height: 70px;
    background-color: #707070;
    margin-left: 9px;
  }
  & .bg-main-nav {
    background-color: #61a24f;
  }
  & .active {
    color: #003e74 !important;
  }
  & .active::after {
    content: ' ';
    display: block;
    background-color: #003e74;
    width: 100%;
    height: 3px;
  }

  & .nav-link {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #7e7f82;
    text-transform: capitalize;
    line-height: 1.85;
  }

  & .nav-link:hover,
  .nav-link:focus {
    color: #5eb0e5;
  }
`;

export const Footer = styled.footer`
  display: block;
  & .copyright-label {
    font-size: 12px;
    font-weight: normal;
    color: #7e7f82;
    text-align: center;
    line-height: 1.25;
  }
`;
