import React from 'react';
import {Container, Navbar, Nav} from 'react-bootstrap';
import {ReactComponent as MainLogo} from '@app/assets/svg/kapsarc-logo.svg';
// import {ReactComponent as ContactButton} from '@app/assets/svg/contact-click.svg';
import {Header} from './MainLayout.style';

const MainLayoutHead = ({path}) => {
  return (
    <Header>
      <Navbar bg="transparent" variant="light">
        <Container fluid>
          <Navbar.Brand href="#home" className="ms-3">
            <MainLogo className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Link href="/" {...(path === '/' ? {active: true} : {})}>
                Home
              </Nav.Link>
              <Nav.Link
                href="/overview"
                {...(path === '/overview' ? {active: true} : {})}
              >
                Digitalization Heatmap
              </Nav.Link>
              {/* <Nav.Link
                href="/projects"
                {...(path === '/projects' ? {active: true} : {})}
              >
                Projects & Processes
              </Nav.Link> */}
              <Nav.Link
                href="/about"
                {...(path === '/about' ? {active: true} : {})}
              >
                About
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Header>
  );
};

export default MainLayoutHead;
