import React from 'react';
import MainLayout from '@app/layouts/MainLayout';
import {
  Container, // Row, Col
} from 'react-bootstrap';

// import HomeBanner from '@app/assets/png/home-banner@2x.png';
import DigitizeIcon from '@app/assets/png/group-172109@2x.png';
import AgilityIcon from '@app/assets/png/group-172112@2x.png';
// import ArtificialIcon from '@app/assets/png/group-172110@2x.png';
// import OpModelIcon from '@app/assets/png/group-172113@2x.png';
import DataAnalysisIcon from '@app/assets/png/group-172111@2x.png';
import MobilityIcon from '@app/assets/png/group-172114@2x.png';

import {RoundedWhiteBox1, PageSectionTitle} from '@styled';

const Home = () => {
  return (
    <MainLayout path="/about">
      <Container fluid style={{minHeight: 'calc(100vh - 190px)'}}>
        <div
          className="mt-5 mb-3 text-center text-muted"
          style={{fontSize: '18px'}}
        >
          Digital Transformation portal supports the Center&apos;s journey
          towards enhancing and improving transparency and efficiency. It aligns
          with the cross-cutting strategic objective of the Center,
          demonstrating our commitment to be innovative, efficient and
          transparent to meet the highest standards of excellence. This portal
          is a window to the Digital Initiatives at KAPSARC.
        </div>
        <PageSectionTitle className="mt-5 text-center">
          Expected Outcome from KAPSARC&apos;s Digital Transformation Programs
        </PageSectionTitle>
        <div className="d-flex w-100 mt-4 px-5">
          <RoundedWhiteBox1
            className="d-flex me-5 flex-column justify-content-center align-items-center"
            style={{flex: 1, boxShadow: 'none'}}
          >
            <img src={DigitizeIcon} alt="" width={158} height={156} />
            <div className="w-50 text-center">
              <PageSectionTitle className="mt-2" style={{fontSize: '18px'}}>
                Digitize
              </PageSectionTitle>
              <p
                className="content mt-3"
                style={{width: '100%', fontSize: '15px'}}
              >
                Applications , workflows and approvals are automated and
                centralized to excel business operations and community
                experience
              </p>
            </div>
          </RoundedWhiteBox1>
          <RoundedWhiteBox1
            className="d-flex  flex-column justify-content-center align-items-center"
            style={{flex: 1, boxShadow: 'none'}}
          >
            <img src={AgilityIcon} alt="" width={158} height={156} />
            <div className="text-center" style={{width: '30%'}}>
              <PageSectionTitle className="mt-2" style={{fontSize: '18px'}}>
                Agility & Scalability
              </PageSectionTitle>
              <p
                className="content mt-3"
                style={{width: '100%', fontSize: '15px'}}
              >
                Infrastructure that allow KAPSARC to deploy new applications and
                features in quick and easy way.
              </p>
            </div>
          </RoundedWhiteBox1>
        </div>
        <div className="d-flex w-100 mt-5 px-5">
          <RoundedWhiteBox1
            className="d-flex me-5 flex-column justify-content-center align-items-center"
            style={{flex: 1, boxShadow: 'none'}}
          >
            <img src={DataAnalysisIcon} alt="" width={158} height={156} />
            <div className="w-50 text-center">
              <PageSectionTitle className="mt-2" style={{fontSize: '18px'}}>
                Data and Analytics
              </PageSectionTitle>
              <p
                className="content mt-3"
                style={{width: '100%', fontSize: '15px'}}
              >
                Discovery, interpretation, and communication of meaningful
                pattern in dashboards and insights to describe, predict, and
                improve business performance.
              </p>
            </div>
          </RoundedWhiteBox1>
          <RoundedWhiteBox1
            className="d-flex flex-column justify-content-center align-items-center"
            style={{flex: 1, boxShadow: 'none'}}
          >
            <img src={MobilityIcon} alt="" width={158} height={156} />
            <div className="w-50 text-center">
              <PageSectionTitle className="mt-2" style={{fontSize: '18px'}}>
                Mobility
              </PageSectionTitle>
              <p
                className="content mt-3"
                style={{width: '100%', fontSize: '15px'}}
              >
                Services requests, community services request, Approvals,
                Announcement, Content and more, anytime anywhere right at
                hand-held devices like mobile or tablets.
              </p>
            </div>
          </RoundedWhiteBox1>
        </div>
        <div className="mt-5 text-center">
          <span>For more information please contact us on - </span>
          <a href="mailto:digitaltransformation@kapsarc.org">
            digitaltransformation@kapsarc.org
          </a>
        </div>
      </Container>
    </MainLayout>
  );
};

export default Home;
