import styled from 'styled-components';

export const InfoBoxWhite = styled.div`
  position: relative;
  background-color: #fff;
  border: 0.5px solid #bdbdbd;
  border-radius: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & .head-label {
    position: absolute;
    top: 20px;
    left: -20px;
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .head-count {
    font-size: 35px;
    line-height: 1.25;
    text-transform: uppercase;
    height: 73px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 90%;
  }
  & .icon {
    margin-bottom: 11px;
    height: 32px;
  }
  & .info-head {
    position: absolute;
    margin: 0;
    top: -20px;
    left: 50px;
  }
`;
