import {get} from 'lodash';
// import moment from 'moment';
import React from 'react';

import styled from 'styled-components';

const ProjectDetailsBox = styled.div`
  box-shadow: rgb(97 162 79 / 13%) 0px 1px 4px 2px;
  border-radius: 3px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 310px;
  & .box-heading {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5em;
    text-align: center;
  }
  & .value-caption {
    font-size: 13px;
    font-weight: bold;
    line-height: 1.5em;
  }
  & .value-row {
    font-size: 20px;
    font-weight: bold;
    line-height: 2rem;
    width: 100%;
    text-align: center;
    padding: 5px;
  }

  & .target-date {
    background-color: rgb(94 176 229 / 10%);
  }

  & .beneficiary {
    background-color: rgba(158, 158, 158, 0.1);
    font-size: 0.9em;
  }

  & .percent-complete {
    background-color: #003e741a;
  }

  & .status {
    background-color: #61a24f1a;
  }

  & .description {
    font-size: 13px;
    background-color: #ffffff1a;
    text-align: center;
  }
`;

const ProjectDetails = ({details}) => {
  const targetDate = get(details, 'Implementation Year', '');
  const status = get(details, '%', '');
  const description = get(details, 'Description', '');
  const projectTitle = get(details, 'Digital Transformation Initiative', '-');
  const beneficiary = get(details, 'Beneficiary', '');

  return projectTitle ? (
    <>
      <div
        style={{
          fontSize: '13px',
          textTransform: 'uppercase',
          fontWeight: 'bold',
        }}
        className="text-center text-muted mb-1"
      >
        Details
      </div>

      <ProjectDetailsBox>
        <div className="box-heading">{projectTitle}</div>
        {beneficiary ? (
          <>
            <div className="mt-3 value-caption text-muted">Beneficiary</div>
            <div className="value-row beneficiary">{beneficiary}</div>
          </>
        ) : null}
        {targetDate ? (
          <>
            <div className="mt-3 value-caption text-muted">
              Implementation Year
            </div>
            <div className="value-row target-date">{targetDate}</div>
          </>
        ) : null}

        {status ? (
          <>
            <div className="mt-3 value-caption text-muted">Status</div>
            <div className="value-row status">{status}</div>
          </>
        ) : null}

        {description ? (
          <>
            <div className="mt-3 value-caption text-muted">Description</div>
            <div className="description">{description}</div>
          </>
        ) : null}
      </ProjectDetailsBox>
    </>
  ) : null;
};

export default ProjectDetails;
