import styled from 'styled-components';

export const ProjectsInfoBox = styled.div`
  box-shadow: rgb(97 162 79 / 13%) 0px 1px 4px 2px;
  ${'' /* border: 0.5px solid #707070; */}
  border-radius:3px;
  padding: 10px;
  padding-right: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  & .info-box {
    padding: 10px 20px;
    margin-right: 10px;
    text-align: center;
    border-radius: 3px;
  }

  & .info-box-title {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: 0px;
    color: #707070;
    width: 80px;
  }
  & .info-box-count {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: 0px;
    color: #464646;
  }
`;

export const FiltersInfoBox = styled.div`
  box-shadow: rgb(97 162 79 / 13%) 1px 1px 4px 2px;
  border: 0.5px solid #ddd;
  border-radius: 3px;
  padding: 10px;
  width: 540px;
  background-color: #fff;
  font-size: 14px;
`;
