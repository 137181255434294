import React from 'react';
import MainLayoutHead from './MainLayoutHead';
import MainLayoutFooter from './MainLayoutFooter';

const MainLayout = ({path, children}) => {
  return (
    <div className="wrapper">
      <MainLayoutHead path={path} />
      {children}
      <MainLayoutFooter />
    </div>
  );
};

export default MainLayout;
