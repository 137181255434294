import React from 'react';
import {Footer} from './MainLayout.style';

const MainLayoutFooter = () => {
  return (
    <Footer className="w-100 mt-4 pb-3">
      <div className="copyright-label w-100">
        © 2023 - King Abdullah Petroleum Studies and Research Centre (KAPSARC)
      </div>
    </Footer>
  );
};

export default MainLayoutFooter;
