import React, {useRef, useEffect} from 'react';
import {init, getInstanceByDom} from 'echarts';

const Echarts = ({
  option,
  onClick,
  onMouseOver,
  onMouseOut,
  style,
  settings,
  loading,
  theme,
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    let chart;

    if (chartRef.current !== null) {
      chart = init(chartRef.current, theme);
    }

    function resizeChart() {
      if (chart) chart.resize();
    }
    window.addEventListener('resize', resizeChart);

    return () => {
      if (chart) {
        chart.dispose();
      }
      window.removeEventListener('resize', resizeChart);
    };
  }, [theme]);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      if (onClick && chart) {
        chart.on('click', onClick);
      }
    }
    return () => {
      if (chartRef.current !== null) {
        const chart = getInstanceByDom(chartRef.current);
        if (onClick && chart) {
          chart.off('click', onClick);
        }
      }
    };
  }, [onClick]);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      if (onMouseOver && chart) {
        chart.on('mouseover', onMouseOver);
      }
    }
    return () => {
      if (chartRef.current !== null) {
        const chart = getInstanceByDom(chartRef.current);
        if (onMouseOver && chart) {
          chart.off('mouseover', onMouseOver);
        }
      }
    };
  }, [onMouseOver]);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      if (onMouseOut && chart) {
        chart.on('mouseout', onMouseOut);
      }
    }
    return () => {
      if (chartRef.current !== null) {
        const chart = getInstanceByDom(chartRef.current);
        if (onMouseOut && chart) {
          chart.off('mouseout', onMouseOut);
        }
      }
    };
  }, [onMouseOut]);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      chart.setOption(option, settings);
    }
  }, [option, settings, theme]);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);

      if (loading === true) chart.showLoading();
      else chart.hideLoading();
    }
  }, [loading, theme]);

  return (
    <div
      ref={chartRef}
      style={{width: '100%', height: '100%', overflow: 'hidden', ...style}}
    />
  );
};

export default Echarts;
