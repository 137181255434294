import React from 'react';
import {Row, Col} from 'react-bootstrap';
import RadioButton from '@app/components/form/CustomRadioButton';
import {FiltersInfoBox} from './projects.style';

const Filters = ({
  entity,
  entityItem,
  year,
  status,
  // department,
  onEntityFilterChange,
  onYearFilterChange,
  onStatusFilterChange,
}) => {
  const isDisabled = () => {
    return entity !== 'Projects' || entityItem ? true : false;
  };

  return (
    <FiltersInfoBox>
      <Row className={entityItem ? 'text-muted' : ''}>
        <Col md={3} className="fw-bold pt-2">
          Type:
        </Col>
        <Col md={9}>
          <div className="d-flex w-75">
            <RadioButton
              label="Projects"
              name="ProjectsOrProcess"
              value="Projects"
              checked={entity === 'Projects' ? true : false}
              onChange={({target}) => {
                // console.log(target.value);
                onEntityFilterChange(target.value);
              }}
              style={{
                fontSize: '14px',
                color: '#000',
                fontWeight: 'bold',
                width: '100px',
              }}
              hoverColor="#5EB0E5"
              selectColor="#5EB0E5"
              disabled={entityItem ? true : false}
            />
            <RadioButton
              label={<span>Departments</span>}
              name="ProjectsOrProcess"
              value="Processes"
              checked={entity === 'Processes' ? true : false}
              onChange={({target}) => {
                onEntityFilterChange(target.value);
              }}
              style={{fontSize: '14px', color: '#000', fontWeight: 'bold'}}
              hoverColor="#5EB0E5"
              selectColor="#5EB0E5"
              disabled={entityItem ? true : false}
            />
          </div>
        </Col>
      </Row>
      <Row className={isDisabled() ? 'text-muted' : ''}>
        <Col md={3} className="fw-bold pt-2">
          Year:
        </Col>
        <Col md={9}>
          <div className="d-flex w-75 justify-content-between">
            <RadioButton
              label="All"
              name="Years"
              value="All"
              checked={year === 'All' ? true : false}
              onChange={({target}) => {
                onYearFilterChange(target.value);
              }}
              style={{fontSize: '14px', color: '#000', fontWeight: 'bold'}}
              hoverColor="#5EB0E5"
              selectColor="#5EB0E5"
              disabled={isDisabled()}
            />
            <RadioButton
              label="2023"
              name="Years"
              value="2023"
              checked={year === '2023' ? true : false}
              onChange={({target}) => {
                onYearFilterChange(target.value);
              }}
              style={{fontSize: '14px', color: '#000', fontWeight: 'bold'}}
              hoverColor="#5EB0E5"
              selectColor="#5EB0E5"
              disabled={isDisabled()}
            />
            <RadioButton
              label="2024"
              name="Years"
              value="2024"
              checked={year === '2024' ? true : false}
              onChange={({target}) => {
                onYearFilterChange(target.value);
              }}
              style={{fontSize: '14px', color: '#000', fontWeight: 'bold'}}
              hoverColor="#5EB0E5"
              selectColor="#5EB0E5"
              disabled={isDisabled()}
            />
            <RadioButton
              label="2025"
              name="Years"
              value="2025"
              checked={year === '2025' ? true : false}
              onChange={({target}) => {
                onYearFilterChange(target.value);
              }}
              style={{fontSize: '14px', color: '#000', fontWeight: 'bold'}}
              hoverColor="#5EB0E5"
              selectColor="#5EB0E5"
              disabled={isDisabled()}
            />
          </div>
        </Col>
      </Row>
      <Row className={isDisabled() ? 'text-muted' : ''}>
        <Col md={3} className="fw-bold pt-2">
          Project Status:
        </Col>
        <Col md={9}>
          <div
            className="d-flex justify-content-between"
            style={{width: '94%'}}
          >
            <RadioButton
              label="All"
              name="Status"
              value="All"
              checked={status === 'All' ? true : false}
              onChange={({target}) => {
                onStatusFilterChange(target.value);
              }}
              style={{fontSize: '14px', color: '#000', fontWeight: 'bold'}}
              hoverColor="#5EB0E5"
              selectColor="#5EB0E5"
              disabled={isDisabled()}
            />
            <RadioButton
              label="Live"
              name="Status"
              value="Live"
              checked={status === 'Live' ? true : false}
              onChange={({target}) => {
                onStatusFilterChange(target.value);
              }}
              style={{fontSize: '14px', color: '#000', fontWeight: 'bold'}}
              hoverColor="#5EB0E5"
              selectColor="#5EB0E5"
              disabled={isDisabled()}
            />
            <RadioButton
              label="In-Progress"
              name="Status"
              value="In-Progress"
              checked={status === 'In-Progress' ? true : false}
              onChange={({target}) => {
                onStatusFilterChange(target.value);
              }}
              style={{fontSize: '14px', color: '#000', fontWeight: 'bold'}}
              hoverColor="#5EB0E5"
              selectColor="#5EB0E5"
              disabled={isDisabled()}
            />
            <RadioButton
              label="Planned"
              name="Status"
              value="Planned"
              checked={status === 'Planned' ? true : false}
              onChange={({target}) => {
                onStatusFilterChange(target.value);
              }}
              style={{fontSize: '14px', color: '#000', fontWeight: 'bold'}}
              hoverColor="#5EB0E5"
              selectColor="#5EB0E5"
              disabled={isDisabled()}
            />
          </div>
        </Col>
      </Row>
    </FiltersInfoBox>
  );
};

export default Filters;
