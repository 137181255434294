import React, {useState, useEffect} from 'react';
import {get} from 'lodash';
import axios from '@app/utils/api';
import {API_URL, API_KEY} from '@app/utils/constants';

import {Echarts} from '@components';
import {
  // ExploreButton,
  PageSectionTitle,
} from '@styled';
// import {ReactComponent as ExploreBtnIcon} from '@app/assets/svg/explore-btn-arrow-icon.svg';

const ActiveProjectsHeatMap = () => {
  const [data, setData] = useState(null);
  const [isLoading, toggleLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${API_URL}/home/departments/projects/heatmap?api-key=${API_KEY}`)
      .then((result) => {
        setData(result.data || null);
      })
      .catch((err) => {
        setData(null);
        console.log('Error while fetching data', err);
      })
      .finally(() => {
        toggleLoading(false);
      });
  }, []);
  return (
    <div style={{position: 'relative', height: '768px'}}>
      <PageSectionTitle className="mt-3 text-center">
        Departmental Projects Heatmap
      </PageSectionTitle>
      <div style={{height: '720px', width: '100%'}}>
        <Echarts
          loading={isLoading}
          option={{
            visualMap: {
              min: get(data, 'minValue', 1),
              max: get(data, 'maxValue', 1000),
              calculable: true,
              orient: 'horizontal',
              left: 'center',
              bottom: '15%',
              show: false,
              color: [
                '#61A24F',
                '#FFD200',
                '#D6D6D6',
                // '#7E7F82',
                // '#27683F',
                // '#77B564',
                // '#707070',
                // '#D8D8D8',
              ],
            },
            series: [
              {
                name: 'KAPSARC',
                type: 'treemap',
                label: {
                  formatter: (params) => {
                    let nodeLabel =
                      params.data.name === '-'
                        ? ''
                        : `${params.data.name} (${params.data.value})`;

                    nodeLabel = `${nodeLabel}${
                      nodeLabel.length
                        ? `\n${'-'.repeat(nodeLabel.length)}\n`
                        : ''
                    }`;

                    return `${nodeLabel}\n${get(
                      params.data,
                      'records',
                      []
                    ).join('\n')}`;
                  },
                  position: [10, 10],
                },
                upperLabel: {
                  show: false,
                  height: 30,
                  position: [0, 20],
                  textBorderColor: 'transparent',
                  color: '#fff',
                },
                levels: [
                  {
                    itemStyle: {
                      borderColor: '#c1c1c1',
                      borderWidth: 0,
                      gapWidth: 1,
                    },
                    upperLabel: {
                      show: false,
                    },
                  },
                  {
                    itemStyle: {
                      borderColor: '#77b564c9',
                      borderWidth: 1,
                      gapWidth: 1,
                    },
                    emphasis: {
                      itemStyle: {
                        borderColor: '#77b564',
                      },
                    },
                  },
                  // {
                  //   colorSaturation: [0.35, 0.5],
                  //   itemStyle: {
                  //     borderWidth: 2,
                  //     gapWidth: 1,
                  //     borderColorSaturation: 0.6,
                  //   },
                  // },
                ],
                breadcrumb: {
                  // show: false,
                  itemStyle: {
                    color: 'rgba(0,0,0,0.3)',
                  },
                  emphasis: {
                    itemStyle: {
                      color: 'rgba(0,0,0,0.7)',
                    },
                  },
                },
                data: get(data, 'data', []),
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default ActiveProjectsHeatMap;
