import React, {useEffect, useState} from 'react';
import {get} from 'lodash';
import ContentLoader from 'react-content-loader';
import qs from 'qs';
import axios from '@app/utils/api';
import {API_URL, API_KEY} from '@app/utils/constants';
import {ProjectsInfoBox} from './projects.style';

const TotalsLoader = (props) => (
  <ContentLoader
    speed={2}
    width={520}
    height={85}
    viewBox="0 0 520 85"
    backgroundColor="#f3f3f3"
    foregroundColor="#74C1EB"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
    <rect x="77" y="0" rx="3" ry="3" width="140" height="11" />
    <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
    <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
    <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
    <rect x="54" y="69" rx="3" ry="3" width="37" height="11" />
    <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
    <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
    <rect x="234" y="0" rx="3" ry="3" width="67" height="11" />
    <rect x="4" y="69" rx="3" ry="3" width="37" height="11" />
    <rect x="159" y="70" rx="3" ry="3" width="37" height="11" />
    <rect x="109" y="70" rx="3" ry="3" width="37" height="11" />
    <rect x="268" y="49" rx="3" ry="3" width="53" height="11" />
    <rect x="328" y="49" rx="3" ry="3" width="72" height="11" />
  </ContentLoader>
);

const TotalProjectsAndProcesses = ({
  department,
  entityItem,
  status,
  entity,
}) => {
  const [data, setData] = useState(null);
  const [isLoading, toggleLoading] = useState(false);
  useEffect(() => {
    toggleLoading(true);
    // query for totals - for all or selected department
    const qParams = {'api-key': API_KEY};
    if (department) {
      qParams.department = department;
    }
    const has2Apply = department || entity === 'Projects';
    if (has2Apply && entityItem) {
      qParams.entityItem = entityItem;
      qParams.entity = entity;
    }

    if (has2Apply && status) {
      qParams.status = status;
      qParams.entity = entity;
    }

    axios
      .get(`${API_URL}/projects/get-totals?${qs.stringify(qParams)}`)
      .then((result) => {
        setData(result.data || null);
      })
      .catch((err) => {
        setData(null);
        console.log('Error while fetching data', err);
      })
      .finally(() => {
        toggleLoading(false);
      });
  }, [department, entityItem, status]);

  const renderProjects = () => {
    const projects = get(data, 'projectsTotals', []);
    return projects.map((item) => {
      return (
        <div
          key={`total-projects-${item.year || 'all'}`}
          className="info-box bg-blue-light-2"
        >
          <div className="info-box-title">
            Total Projects
            <br />
            {item.year ? `in ${item.year}` : <br />}
          </div>
          <div className="info-box-count mt-2">{item.total}</div>
        </div>
      );
    });
  };

  const renderProcesses = () => {
    const processes = get(data, 'processesTotals', []);
    return processes.map((item) => {
      return (
        <div
          key={`total-processes-${item.year || 'all'}`}
          className="info-box bg-blue-light-3"
        >
          <div className="info-box-title">
            Total Processes
            <br />
            {item.year ? `in ${item.year}` : <br />}
          </div>
          <div className="info-box-count mt-2">{item.total}</div>
        </div>
      );
    });
  };

  const getSelectionTail = () => {
    const tails = [];
    tails.push('Kapsarc');
    if (department) {
      tails.push(department);
    }
    if (entityItem) {
      tails.push(entityItem);
    }
    return tails.join(' - ');
  };

  return (
    <div className="me-auto ms-3">
      <h2
        className="bg-blue-dark w-100 pt-3"
        style={{fontSize: '12px', fontWeight: 'bold'}}
      >
        {getSelectionTail()}
      </h2>

      <ProjectsInfoBox className="mt-3" style={{width: 540}}>
        {isLoading ? <TotalsLoader /> : renderProjects()}
      </ProjectsInfoBox>
      <ProjectsInfoBox className="mt-3" style={{width: 144}}>
        {isLoading ? <TotalsLoader /> : renderProcesses()}
      </ProjectsInfoBox>
    </div>
  );
};

export default TotalProjectsAndProcesses;
