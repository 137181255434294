import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  useLocation,
  Route,
  // Redirect,
} from 'react-router-dom';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import {ScrollToTop} from '@components';
import Home from '@modules/home';
// import About from '@modules/about';
import Projects from '@modules/projects';
import DtOverview from '@modules/overview';
// import MaturityAssessment from '@modules/maturityAssessment';
// import Login from '@modules/login';

// import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';
import './App.scss';

const AnimatedApp = () => {
  const location = useLocation();
  return (
    <div id="animation-root">
      <TransitionGroup>
        {/*
      This is no different than other usage of
      <CSSTransition>, just make sure to pass
      `location` to `Switch` so it can match
      the old location as it animates out.
    */}
        <CSSTransition key={location.pathname} classNames="fade" timeout={300}>
          <Switch location={location}>
            {/* <PublicRoute exact path="/login">
              <Login />
            </PublicRoute> */}
            <PublicRoute exact path="/about">
              <Home />
            </PublicRoute>
            {/* <PublicRoute exact path="/projects">
              <Projects />
            </PublicRoute> */}
            <PublicRoute exact path="/overview">
              <DtOverview />
            </PublicRoute>
            <PublicRoute exact path="/">
              {/* <MaturityAssessment /> */}
              <Projects />
            </PublicRoute>
            <Route path="*">
              <div>
                <h1>404 not found!</h1>
              </div>
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="*">
          <AnimatedApp />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
