import React, {useEffect, useState} from 'react';
import {Row, Col} from 'react-bootstrap';
// import {toast} from 'react-toastify';
import {get} from 'lodash';

import axios from '@app/utils/api';
import {API_URL, API_KEY} from '@app/utils/constants';

import ContentLoader from 'react-content-loader';

// import {ReactComponent as UserDeskIcon} from '@app/assets/svg/user-desk-white.svg';
// import {ReactComponent as PadIcon} from '@app/assets/svg/pad-white.svg';
// import {ReactComponent as RefreshIcon} from '@app/assets/svg/refresh-white.svg';
// import {ReactComponent as CalendarIcon} from '@app/assets/svg/calender-green-light.svg';
// import {ReactComponent as CircleTickIcon} from '@app/assets/svg/circle-tick-blue-light.svg';

import {ReactComponent as ProjectsIcon} from '@app/assets/svg/group-172116.svg';
import {ReactComponent as ProcessesIcon} from '@app/assets/svg/group-172120.svg';
import {ReactComponent as ProjectsOnGoingIcon} from '@app/assets/svg/group-172118.svg';

import {
  GreenBorderBoxGradient,
  PageSectionTitle,
  RoundedWhiteBox1,
} from '@styled';

const TotalLoader = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={200}
      height={117}
      viewBox="0 0 200 117"
      backgroundColor="#f3f3f3"
      foregroundColor="#c1c1c1"
      {...props}
    >
      <rect x="16" y="56" rx="3" ry="3" width="150" height="6" />
      <rect x="60" y="87" rx="3" ry="3" width="60" height="20" />
      <rect x="75" y="11" rx="7" ry="7" width="32" height="32" />
      <rect x="41" y="68" rx="3" ry="3" width="100" height="6" />
    </ContentLoader>
  );
};

const TotalsWidget = () => {
  const [data, setData] = useState(null);
  const [isLoading, toggleLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${API_URL}/home/get-totals?api-key=${API_KEY}`)
      .then((result) => {
        setData(result.data || null);
      })
      .catch((err) => {
        setData(null);
        console.log('Error while fetching data', err);
      })
      .finally(() => {
        toggleLoading(false);
      });
  }, []);

  return (
    <Row>
      <Col>
        <RoundedWhiteBox1
          className="d-flex align-items-center justify-content-between"
          style={{
            width: '90%',
            marginLeft: '2rem',
            padding: '20px',
          }}
        >
          {isLoading ? (
            <TotalLoader foregroundColor="#00477F" />
          ) : (
            <>
              <ProjectsIcon />
              <PageSectionTitle>
                Total Number Of Projects From Fy &quot;
                {get(data, 'yearLabel', '-')}&quot;
              </PageSectionTitle>
              <GreenBorderBoxGradient
                style={{width: '100px', height: '80px'}}
                className="d-flex align-items-center justify-content-center fill-grey-gradient"
              >
                <div className="label">{get(data, 'totalProjects', '-')}</div>
              </GreenBorderBoxGradient>
            </>
          )}
        </RoundedWhiteBox1>
      </Col>
      <Col>
        <RoundedWhiteBox1
          className="d-flex align-items-center justify-content-between"
          style={{
            width: '90%',
            marginLeft: '2rem',
            padding: '20px',
          }}
        >
          {isLoading ? (
            <TotalLoader foregroundColor="#74C1EB" />
          ) : (
            <>
              <ProcessesIcon />
              <PageSectionTitle>Total Number of Key Processes</PageSectionTitle>
              <GreenBorderBoxGradient
                style={{width: '100px', height: '80px'}}
                className="d-flex align-items-center justify-content-center fill-grey-gradient"
              >
                <div className="label">{get(data, 'totalProcesses', '-')}</div>
              </GreenBorderBoxGradient>
            </>
          )}
        </RoundedWhiteBox1>
      </Col>
      <Col>
        <RoundedWhiteBox1
          className="d-flex align-items-center justify-content-between"
          style={{
            width: '90%',
            marginLeft: '2rem',
            padding: '20px',
          }}
        >
          {isLoading ? (
            <TotalLoader foregroundColor="#235D37" />
          ) : (
            <>
              <ProjectsOnGoingIcon />
              <PageSectionTitle>
                Total Projects For {get(data, 'currentYear', '-')}
              </PageSectionTitle>
              <GreenBorderBoxGradient
                style={{width: '100px', height: '80px'}}
                className="d-flex align-items-center justify-content-center fill-grey-gradient"
              >
                <div className="label">
                  {get(data, 'currentYearOnGoingProjects', '-')}
                </div>
              </GreenBorderBoxGradient>
            </>
          )}
        </RoundedWhiteBox1>
      </Col>
      {/* <Col>
        <InfoBoxWhite className="p-2">
          {isLoading ? (
            <TotalLoader foregroundColor="#77B564" />
          ) : (
            <>
              <div className="icon">
                <CalendarIcon />
              </div>
              <div className="head-label">
                Current Quarter
                <br />
                Status - {get(data, 'currentQuarter', '-')}{' '}
                {get(data, 'currentYear', '-')}
              </div>
              <div
                className="head-count bg-green-light"
                style={{fontSize: '20px', lineHeight: 2.15}}
              >
                {get(data, 'currentQuarterStatus', '-')}
              </div>
            </>
          )}
        </InfoBoxWhite>
      </Col>
      <Col>
        <InfoBoxWhite className="p-2">
          {isLoading ? (
            <TotalLoader foregroundColor="#74C1EB" />
          ) : (
            <>
              <div className="icon">
                <CircleTickIcon />
              </div>
              <div className="head-label">
                Completed
                <br />
                Deliverables For {get(data, 'currentQuarter', '-')}
              </div>
              <div className="head-count bg-blue-light">
                {get(data, 'currentQuarterDeliverablesCompleted', '-')}/
                {get(data, 'currentQuarterDeliverables', '-')}
              </div>
            </>
          )}
        </InfoBoxWhite>
      </Col> */}
    </Row>
  );
};

export default TotalsWidget;
