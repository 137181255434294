import React, {useEffect, useState} from 'react';
import {get} from 'lodash';
import {Echarts} from '@components';
import axios from '@app/utils/api';
import {API_URL, API_KEY} from '@app/utils/constants';

import RadioButton from '@app/components/form/CustomRadioButton';
import {PageSectionTitle} from '@styled';
import {InfoBoxWhite} from './overview.style';

const ProjectsAndProcesses = () => {
  const [radioValue, setRadioValue] = useState('Projects');
  const [data, setData] = useState(null);
  const [isLoading, toggleLoading] = useState(false);

  useEffect(() => {
    toggleLoading(true);
    axios
      .get(
        `${API_URL}/home/departments-breakdown?entity=${radioValue}&api-key=${API_KEY}`
      )
      .then((result) => {
        setData(result.data || null);
      })
      .catch((err) => {
        setData(null);
        console.log('Error while fetching data', err);
      })
      .finally(() => {
        toggleLoading(false);
      });
  }, [radioValue]);

  return (
    <InfoBoxWhite
      className="mt-2"
      style={{height: '768px', paddingBottom: '15px'}}
    >
      <PageSectionTitle className="mt-3">
        Departmental Breakdown Of Digitalization Initiatives
      </PageSectionTitle>

      <div className="d-flex w-100 justify-content-end">
        <RadioButton
          label="Projects"
          name="ProjectsOrProcess"
          value="Projects"
          checked={radioValue === 'Projects' ? true : false}
          onChange={({target}) => {
            console.log(target.value);
            setRadioValue(target.value);
          }}
        />
        <RadioButton
          label="Key Processes"
          name="ProjectsOrProcess"
          value="Processes"
          checked={radioValue === 'Processes' ? true : false}
          onChange={({target}) => {
            console.log(target.value);
            setRadioValue(target.value);
          }}
        />
      </div>
      <div style={{height: 'calc(100% - 5px)', width: '100%'}}>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <Echarts
            option={{
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  // Use axis to trigger tooltip
                  type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
                },
              },
              legend: {
                top: 'bottom',
                show: radioValue === 'Processes' ? false : true,
              },
              grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
                show: false,
              },
              xAxis: {
                type: 'value',
                show: false,
              },
              yAxis: {
                type: 'category',
                data: get(data, 'departments', []),
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
              },
              barWidth: 23,
              series: get(data, 'series', []),
            }}
          />
        )}
      </div>
    </InfoBoxWhite>
  );
};

export default ProjectsAndProcesses;
