/* eslint-disable  */
import React, {useCallback, useEffect, useState} from 'react';
import MainLayout from '@app/layouts/MainLayout';
import {Container} from 'react-bootstrap';
import {Echarts} from '@components';
import {cloneDeep, get} from 'lodash';
import qs from 'qs';
import axios from '@app/utils/api';
import {API_URL, API_KEY} from '@app/utils/constants';
import {PageSectionTitle} from '@styled';
import {InfoBoxWhite} from '../overview/overview.style';
import TotalProjectsAndProcesses from './TotalProjectsAndProcesses';
import Filters from './Filters';
import ProjectDetails from './ProjectDetails';

const rootNode = {
  name: 'KAPSARC',
  children: [],
  totalInitiatives: 0,
  totalProcesses: 0,
};

const Projects = () => {
  const [data, setData] = useState(null);
  const [filterData, setFilterData] = useState(rootNode);
  const [isLoading, toggleLoading] = useState(false);

  const [selectedDepartment, setDepartment] = useState('');
  const [entityFilter, setEntityFilter] = useState('Projects');
  const [yearFilter, setYearFilter] = useState('All');
  const [selectedEntityItem, setEntityItem] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [subEntityItem, setSubEntityItem] = useState(null);

  const [statusFilter, setStatusFilter] = useState('All');

  useEffect(() => {
    // query for totals - for all or selected department
    const qParams = {'api-key': API_KEY};
    if (selectedDepartment) {
      qParams.department = selectedDepartment;
    }
    if (entityFilter) {
      qParams.entity = entityFilter;
    }
    if (yearFilter) {
      qParams.year = yearFilter;
    }

    if (statusFilter) {
      qParams.status = statusFilter;
    }
    let has2Fetch = true;
    if (entityFilter === 'Processes') {
      qParams.year = 'All';
      qParams.status = 'All';
      if (selectedDepartment && selectedEntityItem) {
        qParams.entityItem = selectedEntityItem.name;
      }
    } else {
      if (selectedEntityItem) {
        // has2Fetch = false;
        qParams.entityItem = selectedEntityItem.name;
      }
    }
    if (has2Fetch) {
      toggleLoading(true);
      axios
        .get(`${API_URL}/projects/fetch-data?${qs.stringify(qParams)}`)
        .then((result) => {
          setData(result.data || null);
        })
        .catch((err) => {
          setData(null);
          console.log('Error while fetching data', err);
        })
        .finally(() => {
          toggleLoading(false);
        });
    }
  }, [
    selectedDepartment,
    entityFilter,
    yearFilter,
    statusFilter,
    selectedEntityItem,
  ]);

  useEffect(() => {
    // console.log('Global Data Changed', data);
    setFilterData(data || rootNode);
  }, [data]);

  const handleOnNodeClick = useCallback(
    (params) => {
      // printing data name in console
      var nodeType = params.data.nodeType || '';
      const {children, ...clonedData} = data || rootNode;
      let filteredChildren = cloneDeep(children);
      console.log('Params', params, nodeType);
      var doNotSet = false;
      switch (nodeType) {
        case 'root': //Kapsarc
          // show all departments
          filteredChildren = filteredChildren.map((item) => {
            item.collapsed = true;
            return item;
          });
          setEntityItem(null);
          setSubEntityItem(null);
          setDepartment('');
          clonedData.collapsed = false;
          break;
        case 'department': {
          //department
          if (params.collapsed == true) {
            setEntityItem(null);
            setSubEntityItem(null);
            setDepartment('');
          } else {
            // show selected department only
            filteredChildren = filteredChildren.filter(
              (item) => item.name === params.data.name
            );
            if (filteredChildren.length) {
              filteredChildren[0].collapsed = false;
              setDepartment(filteredChildren[0].name);
            }
          }
          break;
        }
        case 'Initiatives':
          if (params.collapsed == true) {
            setSubEntityItem(null);
          } else {
            setSubEntityItem(params.data);
          }
          break;
        // case 'processes':
        case 'entity': {
          // 0=department->children->find
          //processes/projects based on selection (currently processes on 3rd level)

          if (params.collapsed == true) {
            setEntityItem(null);
          } else {
            if (entityFilter === 'Projects') {
              filteredChildren = filteredChildren.filter(
                (item) => item.name === params.data.name
              );
              filteredChildren[0].collapsed = false;
              filteredChildren[0].label = {
                width: 100,
                overflow: 'break',
              };
              setEntityItem(filteredChildren[0]);
              // clonedData.collapsed = false;
            } else {
              // show selected department only
              filteredChildren[0].children =
                filteredChildren[0].children.filter(
                  (item) => item.name === params.data.name
                );
              if (filteredChildren[0].children.length) {
                filteredChildren[0].children[0].collapsed = false;
                setEntityItem(filteredChildren[0].children[0]);
              }
            }
          }
          setSubEntityItem(null);
          break;
        }
        default:
          doNotSet = true;
          break;
      }
      if (!doNotSet) {
        setFilterData({
          ...clonedData,
          children: filteredChildren,
        });
      }
    },
    [data]
  );

  const handleOnMouseOver = useCallback(
    (params) => {
      // console.log('On Hover', params);
      const nodeType = params.data.nodeType || '';
      const entityType = params.data.totalEntity || '';
      if (
        nodeType === 'Initiatives' ||
        (nodeType === 'entity' && entityType === 'Processes')
      ) {
        // show floating div
        // console.log('Floating Div here', params.data);
        setProjectData(params.data);
      }
    },
    [data, projectData, setProjectData]
  );

  const handleOnMouseOut = useCallback(
    (params) => {
      if (projectData) {
        // console.log('On Out', params);
        setProjectData(null);
      }
    },
    [data, projectData, setProjectData]
  );

  const handleEntityFilterChange = useCallback(
    (val) => {
      setEntityFilter(val);
    },
    [entityFilter]
  );

  const handleYearFilterChange = useCallback(
    (val) => {
      setYearFilter(val);
    },
    [yearFilter]
  );

  const handleStatusFilterChange = useCallback(
    (val) => {
      setStatusFilter(val);
    },
    [statusFilter]
  );

  const isSelectedEntityProject = () => {
    return selectedEntityItem && selectedEntityItem.totalEntity === 'Processes';
  };

  return (
    <MainLayout path="/">
      <Container fluid style={{minHeight: 'calc(100vh - 173px)'}}>
        <PageSectionTitle className="mt-3 text-center">
          DT Projects And Processes
        </PageSectionTitle>
        <InfoBoxWhite
          className="mt-3"
          style={{
            height: 832,
            position: 'relative',
            alignItems: 'flex-start',
          }}
        >
          <div style={{position: 'absolute', top: 180, left: 10}}>
            <TotalProjectsAndProcesses
              department={selectedDepartment}
              entity={entityFilter}
              entityItem={selectedEntityItem ? selectedEntityItem.name : ''}
              status={statusFilter}
            />
          </div>
          <div style={{height: 832, width: '80%'}}>
            <Echarts
              loading={isLoading}
              option={{
                tooltip: {
                  trigger: 'item',
                  triggerOn: 'mousemove',
                  formatter: function (params, ticket, callback) {
                    var nodeType = params.data.nodeType || '';
                    var html = params.name;
                    switch (nodeType) {
                      case 'root': //Kapsarc
                      case 'department': //department
                        html = `<div class="tooltip-head-label">Total Initiatives</div>
                                <div class="tooltip-count-label">${params.data.totalInitiatives}</div>
                                <div class="tooltip-head-label">Total Processes</div>
                                <div class="tooltip-count-label">${params.data.totalProcesses}</div>`;
                        break;
                      case 'entity': //processes/projects
                        html = `<div class="tooltip-head-label">Total ${
                          params.data.totalEntity || '-'
                        }</div><div class="tooltip-count-label">${
                          params.data.total
                        }</div>`;
                        // <ul class="tooltip-list-items">${params.data.children
                        //   .map((item) => `<li>${item.name}</li>`)
                        //   .join('')}</ul>
                        break;
                      case 'processes': //initiative;

                      case 'projects':
                        html = `<div class="tooltip-head-label">Initiative</div>${
                          params.name
                        }<hr/><br/>${
                          params.data.details || '-'
                        }<div class="tooltip-head-label">Implementation Time(months)</div>${
                          params.data.duration || '-'
                        }<div class="tooltip-head-label">Year of Implementation(2023, 2024, 2025)</div>${
                          params.data.year || '-'
                        }`;
                        break;
                    }
                    return `<div class="tooltip-root">${html}</div>`;
                  },
                },
                series: [
                  {
                    type: 'tree',
                    data: [filterData],
                    top: '1%',
                    left: '25%',
                    bottom: '1%',
                    right: '30%',
                    symbolSize: 12,
                    symbol: 'circle',
                    // edgeForkPosition: '10%',
                    // layout: "radial",
                    // initialTreeDepth: 3,
                    label: {
                      position: 'left',
                      verticalAlign: 'middle',
                      align: 'right',
                      fontSize: 13,
                      fontWeight: 'bold',
                      // overflow: "break",
                      // width: 200,
                    },
                    leaves: {
                      label: {
                        position: 'right',
                        verticalAlign: 'middle',
                        align: 'left',
                      },
                    },
                    emphasis: {
                      focus: 'descendant',
                    },
                    lineStyle: {
                      color: '#7E7F82',
                      width: 1,
                    },
                    itemStyle: {
                      color: '#61A24F',
                    },
                    expandAndCollapse: true,
                    animationDuration: 550,
                    animationDurationUpdate: 750,
                  },
                ],
              }}
              onClick={handleOnNodeClick}
              onMouseOver={handleOnMouseOver}
              onMouseOut={handleOnMouseOut}
            />
          </div>

          <div style={{position: 'absolute', top: 40, left: 25}}>
            <Filters
              entity={entityFilter}
              year={yearFilter}
              status={statusFilter}
              department={selectedDepartment}
              entityItem={selectedEntityItem ? selectedEntityItem.name : ''}
              onEntityFilterChange={handleEntityFilterChange}
              onYearFilterChange={handleYearFilterChange}
              onStatusFilterChange={handleStatusFilterChange}
            />
          </div>

          {projectData &&
          (!selectedEntityItem || !isSelectedEntityProject()) &&
          !subEntityItem ? (
            <div style={{position: 'absolute', top: 40, right: 40, width: 310}}>
              <h5 style={{overflowWrap: 'break-word'}} className="bg-blue-dark">
                {projectData.name}
              </h5>
              <p className="text-muted">
                {get(projectData, 'details.Description', '-')}
              </p>
            </div>
          ) : null}
          {isSelectedEntityProject() || subEntityItem ? (
            <div style={{position: 'absolute', top: 40, right: 40, width: 310}}>
              <ProjectDetails
                details={get(
                  isSelectedEntityProject()
                    ? selectedEntityItem
                    : subEntityItem,
                  'details',
                  {}
                )}
              />
            </div>
          ) : null}
          {entityFilter === 'Processes' ? (
            <div
              style={{
                position: 'absolute',
                bottom: 10,
                right: 40,
                width: 310,
                fontSize: '13px',
              }}
              className="text-muted"
            >
              <span style={{fontWeight: 'bold'}}>Note:</span> Processes that
              have potential Digitalization solution are shown
            </div>
          ) : null}
        </InfoBoxWhite>
      </Container>
    </MainLayout>
  );
};

export default Projects;
